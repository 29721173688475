import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { useLax } from 'utils/hooks/lax';
import {
    Button,
    Topic,
    TopicH1,
    TopicHeading,
    TopicParagraph,
    SmallCitation,
    SmallCitationQuote,
    SmallCitationData,
    CirclesBackground,
    Grid,
    FeatureBody,
    FeatureItem,
} from 'components';
import {
    loioPageStyles,
    LoioBody,
    LoioProcess,
    LoioProcessRow,
    LoioProcessImage,
    LoioProcessDescription,
} from 'components/loio-page/loio-page';
import LoioLogoSVG from '../img/loio/loio-logo.svg';

export default function LoioPage(): JSX.Element {
    const data = useStaticQuery(imgQuery);

    useLax();

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Phase One Karma — Loio</title>
                <meta name="description"
                      content="Loio — a shortcut to perfect legal documents."
                />
            </Helmet>

            <LoioBody>
                <Topic>
                    <TopicH1>Loio — a shortcut to perfect legal documents.</TopicH1>
                    <TopicParagraph className={loioPageStyles.loioTopic__description}>
                        Advanced styling, comparison, and AI-analysis for legal contracts in one add-in.
                    </TopicParagraph>
                </Topic>

                <Grid
                    data-aos="fade-down"
                    data-aos-offset="200"
                    data-aos-delay={60}
                >
                    <Img
                        fluid={data.loioHero.childImageSharp.fluid}
                        fadeIn={true}
                        className={loioPageStyles.loioUi}
                    />
                </Grid>

                <CirclesBackground
                    style={{
                        backgroundPosition: '-100px 0px',
                        backgroundSize: '2200px auto'
                    }}
                >
                    <Topic>
                        <TopicHeading>Crafting Loio</TopicHeading>
                        <TopicParagraph>
                            Loio aspires to transform the routine tiresome digital paperwork for lawyers to free
                            them for the complex tasks that only the human mind can complete.
                        </TopicParagraph>
                        <TopicParagraph>
                            The idea of creating Loio takes root alongside P1K’s upbringing of other projects. The
                            team noticed that as the company continued to grow, our lawyers were swamped by a contract
                            blast that needed careful review and editing. That was a tiresome experience for the legal
                            department, so we decided to help out.
                        </TopicParagraph>
                    </Topic>

                    <FeatureBody>
                        <FeatureItem
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-delay={60 * 1}
                            title="Private practice lawyers"
                            description="Get through paperwork faster and make fewer errors with styling or numbering."
                            image={data.featurePrivate.childImageSharp.fluid}
                        />
                        <FeatureItem
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-delay={60 * 2}
                            title="In-house lawyers"
                            description="Level up your legal department’s paperwork with AI-analysis to help the business grow."
                            image={data.featureInHouse.childImageSharp.fluid}
                        />
                        <FeatureItem
                            data-aos="fade-down"
                            data-aos-offset="200"
                            data-aos-delay={60 * 3}
                            title="Law firms"
                            description="Process legal agreements faster and with greater accuracy to keep more clients satisfied."
                            image={data.featureFirm.childImageSharp.fluid}
                        />
                    </FeatureBody>


                    <SmallCitation className={loioPageStyles.loioAiPower}>
                        <SmallCitationQuote className={loioPageStyles.loioCitation__quote}>
                            An AI-powered response to help lawyers take back control over their work.
                        </SmallCitationQuote>
                    </SmallCitation>

                    <LoioProcess>
                        <LoioProcessRow>
                            <LoioProcessImage fluid={data.process1.childImageSharp.fluid} />
                            <LoioProcessDescription>
                                We did the research — legal professionals from all over the world were leading an
                                uphill battle with the workload, especially working long hours to proofread and
                                edit documents.
                            </LoioProcessDescription>
                        </LoioProcessRow>

                        <LoioProcessRow>
                            <LoioProcessImage fluid={data.process2.childImageSharp.fluid} />
                            <LoioProcessDescription>
                                Since then, Loio has been taking its current shape: a smart blend of power and
                                simplicity in MS Word add-in format, covering document styling, numbering, tracking
                                contract versions, and up-to-date AI-analysis.
                            </LoioProcessDescription>
                        </LoioProcessRow>
                    </LoioProcess>

                    <SmallCitation className={loioPageStyles.loioCitation}>
                        <SmallCitationData>
                            <img src={LoioLogoSVG} className={loioPageStyles.loioCitation__logo} alt="Loio" />
                        </SmallCitationData>
                        <SmallCitationQuote className={loioPageStyles.loioCitation__quote}>
                            Loio already saves our lawyers’ time — now the world gets to know this legal hero!
                        </SmallCitationQuote>
                        <SmallCitationData className={loioPageStyles.loioCitation__visit}>
                            <Button href="https://loio.com/" target="_blank">
                                Visit Loio
                            </Button>
                        </SmallCitationData>
                    </SmallCitation>
                </CirclesBackground>
            </LoioBody>
        </>
    );
}

export const imgQuery = graphql`
    query {
        loioHero: file(relativePath: { eq: "loio/loio-hero.png" }) {
            childImageSharp {
                fluid(maxWidth: 738, srcSetBreakpoints: [987], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }

        featureFirm: file(relativePath: { eq: "loio/firm.png" }) {
            childImageSharp {
                fluid(maxWidth: 90, srcSetBreakpoints: [90], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        featurePrivate: file(relativePath: { eq: "loio/private.png" }) {
            childImageSharp {
                fluid(maxWidth: 90, srcSetBreakpoints: [90], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
        featureInHouse: file(relativePath: { eq: "loio/in-house.png" }) {
            childImageSharp {
                fluid(maxWidth: 90, srcSetBreakpoints: [90], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }

        process1: file(relativePath: { eq: "loio/process-1.png" }) {
            childImageSharp {
                fluid(maxWidth: 345, srcSetBreakpoints: [345], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }

        process2: file(relativePath: { eq: "loio/process-2.png" }) {
            childImageSharp {
                fluid(maxWidth: 345, srcSetBreakpoints: [345], quality: 95) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        }
    }
`;
