import React from 'react';
import Img from 'gatsby-image';
import styles from './loio-page.module.scss';
import { Grid } from 'components';

export { styles as loioPageStyles };

export function LoioBody(props: React.PropsWithChildren<{}>): JSX.Element {
    return <div className={styles.loio__body} {...props} />;
}


export function LoioProcess(props: React.PropsWithChildren<any>): JSX.Element {
    return <Grid
        className={styles.process}
        containerClassName={styles.processContainer}
        {...props}
    />;
}

export function LoioProcessRow(props: React.PropsWithChildren<any>): JSX.Element {
    return <div className={styles.process__row} {...props} />;
}

export function LoioProcessImage(props: React.PropsWithChildren<any>): JSX.Element {
    return <Img className={styles.process__image} {...props} />;
}

export function LoioProcessDescription(props: React.PropsWithChildren<any>): JSX.Element {
    return <p className={styles.process__description} {...props} />;
}